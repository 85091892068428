body {
    margin: 0px;
    padding: 0px;

    background-color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);

    color: rgb(255, 255, 255);
}

.text-white {
    color: rgb(255, 255, 255);
}

.fabButton {
    position: absolute !important;
    bottom: 5px;
    right: 5px;
    z-index: 1000000;
}


.background {
    background-image: url("/images/block/background-main.png");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.backgroundJuego {
    min-height: 100vh;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(0, 0, 0);
}

.fondoTitulo {
    background-color: rgb(57 157 202);
    color: rgb(255 255 255);
    padding: 5px;
    text-align: center;
}

.background-forms {
    background-color: rgb(13 82 115);
    border:1px solid #a7b3c159;
    padding: 15px;
}

.background-forms-secundario {
    background-color: rgb(78 13 115);
    padding: 15px;
}

.background-list-cards {
    border:1px solid #a7b3c159;
    background-color: rgb(216 203 210 / 24%);
    border-radius: 5px;
}

img {
    border-radius: 10px;
}

/* media query */

@media screen and (max-width: 992px) {
  }
  

  @media screen and (max-width: 600px) {
  }